<template>
  <v-list-item-content v-if="modelValue">
    <v-list-item-subtitle>Verwendung auf Stufen</v-list-item-subtitle>
    <v-list-item-title>
      <v-row no-gutters>
        <v-col v-for="grade in grades" :key="grade.id"
          ><v-checkbox
            dense
            :readonly="readonly"
            hide-details
            v-model="modelValue[grade.id]"
            :label="grade.code"
          />
        </v-col>
      </v-row>
    </v-list-item-title>
  </v-list-item-content>
</template>
<script>
export default {
  props: {
    readonly: { type: Boolean, default: false },
    value: { type: Object },
  },
  data() {
    return {
      grades: [],
      modelValue: this.value,
    };
  },
  async created() {
    this.grades = await this.apiList({ resource: 'common/grade' });
  },
  watch: {
    value() {
      this.modelValue = this.value;
    },
  },
};
</script>
<style scoped>
:deep(.v-input--checkbox.v-input--dense),
:deep(.v-input--selection-controls) {
  margin-top: -2px;
}
</style>
